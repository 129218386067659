import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { FaGlobeAfrica } from 'react-icons/fa';
import Program from '../components/program'
import navStyles from '../components/navigation.module.css';
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Logo from '../components/logo'

class ZuidAfrika extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    //Note single '[]'   
    const [author] = get(this, 'props.data.allContentfulPerson.edges')   
    //Vs multiple entries not containing '[]'
    const Bijbelclubs = get(this, 'props.data.allContentfulBijbelKlub.edges')

    return (
      <Layout>
        <Helmet title={siteTitle} />
        <Logo author={author} />
        <div className="wrapper">        
        <ul className="header-list">
          <li><div className={navStyles.iconimage}><FaGlobeAfrica  className={navStyles.icon}/></div></li>
          <h2><u>B</u>ijbelclubs</h2>          
          </ul>
          <hr className="hr-repeat"></hr>
          <br/>
        <h2>Wat is mijn wekelijkse programma?</h2>
        <hr/>
        <iframe src="https://prezi.com/p/pygmfipcxs96/embed/" id="iframe_container" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" allow="autoplay; fullscreen" height="500" width="100%"></iframe>
        <hr/>        
        <p> locaties van verschillende Bijbelclubs:</p>
        <iframe title="Lea in Afrika" src="https://www.google.com/maps/d/embed?mid=1p7Q6HQ01TaO_NZlaGRfoF91nvWP7LOkv" width="100%" height="480"></iframe>
        </div>
      </Layout>
    )
  }
}

export default ZuidAfrika

export const pageQuery = graphql`
  query ZuidAfrikaQuery {
    allContentfulPerson(filter: { id: { eq: "e339d456-b3e8-5213-8d99-cf8ebb14a0a0" } }) {
      edges {
        node {
          name
          shortBio  {
            childMarkdownRemark {
              html
            }
          }
          headline
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }    
  }
`
